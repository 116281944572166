<template>
  <v-container fluid>
    <TheTitle text="Mapa Mental" icon="mdi-file-table">
      <!-- {{ groupList }} -->
      <v-row justify="center">
        <v-col>
          <v-select
            dense
            hide-details
            solo
            label="Selecione o setor desejado"
            :items="sectors"
            item-text="name"
            item-value="id"
            v-model="selectedSector"
            outlined
            flat
            @change="loadItensOnChange"
          ></v-select>
        </v-col>
      </v-row>
    </TheTitle>

    <v-row>
      <v-col cols="6" flat v-if="loading">
        <v-card
          flat
          class="rounded-xl pa-5 text-center"
          rounded
          outlined
          color="white"
        >
          <h1 class="ma-0 pa-0 font-weight-bold mb-0" style="color: #262585ec">
            Repositórios
          </h1>
          <v-container
            class="fill-height text-center"
            justify="center"
            align="center"
          >
            <h1
              class="text-center mx-auto"
              v-if="repositories.length === 0"
              style="color: #262585ec"
            >
              Não há repositórios na atividade
            </h1>
            <v-row align="center" justify="center">
              <v-col
                cols="6"
                class="text-center"
                v-for="(item, i) in repositories"
                :key="i"
              >
                <span>{{ item }}</span>
                <!-- Using span for each item -->
                <span v-if="i < repositories.length - 1">-&nbsp;</span>
                <!-- Condition to check if it's not the last item -->
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>

      <v-col cols="6" flat v-if="loading">
        <v-card
          flat
          class="rounded-xl pa-5 text-center"
          rounded
          outlined
          color="white"
        >
          <h1 class="ma-0 pa-0 font-weight-bold" style="color: #262585ec">
            Principais atividades
          </h1>
          <v-container
            class="fill-height text-center d-flex flex-row align-center justify-center"
            justify="center"
            align="center"
          >
            <span
              v-for="(activity, i) in object.activities"
              :key="i"
              style="color: black; margin-right: 5px"
            >
              {{ activity.name }}
              <span v-if="i < object.activities.length - 1">- </span>
              <!-- Condition to check if it's not the last item -->
            </span>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <!-- <div v-if="loading" no-gutters class="ma-5 ml-1"> -->
    <v-row v-if="loading" class="d-flex justify-center">
      <v-col
        :cols="12 / types.length"
        v-for="(type, i) in types"
        :key="i"
        class="pa-2"
      >
        <!-- Cartão Horizontal -->
        <v-card
          flat
          dark
          class="py-3 my-2 text-center"
          rounded
          outlined
          color="white"
          style="width: 100%"
        >
          <p class="mb-0 ml-2 mt-1 font-weight-bold" style="color: #262585ec">
            {{ type.value }}. {{ type.name }}
          </p>
        </v-card>

        <!-- Div envolvendo os Cartões Verticais -->
        <div
          class="myclass px-1"
          style="max-height: calc(5 * 100px + 5 * 20px); margin-top: 16px"
        >
          <v-row class="d-flex justify-start">
            <template v-for="(step, j) in getSteps()">
              <v-col
                v-if="step.type == type.value"
                :key="'step-' + j"
                cols="12"
              >
                <v-card
                  flat
                  dark
                  class="rounded-lg pa-5"
                  color="white"
                  style="color: #262585ec; padding-left: 15px; width: 100%"
                >
                  <!-- Visual Redondo com Cor Baseada em step.legal -->
                  <v-chip
                    :key="i"
                    :style="{
                      'background-color': getColor(step.legal),
                      color: getDarkerColor(getColor(step.legal))
                    }"
                    class="font-weight-bold mb-2"
                    small
                    label
                  >
                    {{ step.stepLegal }}
                  </v-chip>

                  <h3 class="font-weight-bold mb-2" style="display: block">
                    {{ step.stepName }}
                  </h3>
                  <v-row no-gutters align="end" justify="end">
                    <v-col cols="8">
                      <span
                        v-if="step.description"
                        class="caption text-justify"
                        style="display: block; flex-grow: 1"
                      >
                        Descrição:
                      </span>
                    </v-col>
                    <v-col class="text-right">
                      <v-icon
                        color="#36358E"
                        v-if="step.description"
                        icon
                        @click="toggleDescription(j)"
                        >{{
                          expandedSteps.includes(j)
                            ? 'mdi-chevron-up'
                            : 'mdi-chevron-down'
                        }}</v-icon
                      >
                    </v-col>
                  </v-row>

                  <div
                    class="caption text-justify"
                    v-if="step.description"
                    v-html="
                      expandedSteps.includes(j)
                        ? step.description
                        : step.description.substring(0, 100) + '...'
                    "
                  ></div>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <!-- </div> -->

    <div v-if="loading">
      <v-divider v-if="object" class="my-15"></v-divider>
      <h2 class="text-center text-h4 font-weight-bold mb-15" v-if="object">
        Grupos de dados
      </h2>
      <!-- third row -->
      <v-row justify="center">
        <v-col cols="2" v-for="(group, i) in groupList" :key="i">
          <v-card
            flat
            dark
            color="#3B4DD9"
            class="rounded-xl pa-5 text-center"
            rounded
            outlined
          >
            <v-container class="fill-height text-center">
              <p class="text-center mx-auto">
                <span v-for="(data, j) in group.dataList" :key="j">
                  {{ data.name }}
                  <!-- Add a condition here to check if it's not the last item -->
                  <span v-if="j < group.dataList.length - 1">-</span>
                </span>
              </p>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from 'axios'
import tinycolor from 'tinycolor2'
import { baseApiUrl, showError } from '@/global'
import TheTitle from '@/components/TheTitle'
// import TheDialog from '@/components/TheDialog'

export default {
  name: 'Group',
  components: {
    TheTitle
  },
  data: () => ({
    data: [],
    expandedSteps: [],
    sectors: [],
    activities: [],
    dataGroups: [],
    selectedSector: {},
    types: [
      { name: 'Coleta', value: 1, color: '#264d59' },
      { name: 'Utilização', value: 2, color: '#43978d' },
      { name: 'Compartilhamento', value: 3, color: '#43978d' },
      { name: 'Retenção', value: 4, color: '#f9ad6a' },
      { name: 'Descarte', value: 5, color: '#d46c4e' }
    ],
    object: null,
    repositories: [],
    loading: false,
    groupList: []
  }),
  methods: {
    async loadItensOnChange () {
      this.loading = false
      const obj = this.sectors.find(
        element => element.id === this.selectedSector
      )

      this.object = obj

      if (!this.object) {
        return false
      }

      const activities = this.filteredActivities(this.selectedSector)
      this.object.activities = []

      for (let i = 0; i < activities.length; i++) {
        const element = activities[i]
        const rawData = await this.getById(element.id)

        this.object.activities.push(rawData)
        for (let j = 0; j < rawData.list.length; j++) {
          const step = rawData.list[j]
          if (!this.repositories.includes(step.placeName))
            this.repositories.push(step.placeName)
        }
      }

      this.groupList = await this.filteredDataGroups()
      this.loading = true
    },

    async getById (id) {
      const url = `${baseApiUrl}/activity/${id}`
      this.$store.dispatch('setLoadingInfo', true)
      return await axios
        .get(url)
        .then(res => {
          this.$store.dispatch('setLoadingInfo', false)
          return res.data
        })
        .catch(() => {
          this.$store.dispatch('setLoadingInfo', false)
          return []
        })
    },

    getDarkerColor (color) {
      return tinycolor(color).darken(55).toString()
    },

    filteredActivities (sectorId) {
      return this.activities.filter(value => value.idSector === sectorId)
    },
    toggleDescription (index) {
      if (this.expandedSteps.includes(index)) {
        this.expandedSteps = this.expandedSteps.filter(item => item !== index)
      } else {
        this.expandedSteps.push(index)
      }
    },

    async filteredDataGroups () {
      let filteredDataGroups = []
      let steps = this.getSteps()

      for (let i = 0; i < steps.length; i++) {
        const step = steps[i]
        console.log(step)
        console.log(this.dataGroups)
        let foundDataGroup = this.dataGroups.find(
          datagroup => datagroup.id == step.idDataGroup
        )
        console.log(foundDataGroup)
        // console.log(foundDataGroup)
        if (
          foundDataGroup &&
          !filteredDataGroups.find(el => el == foundDataGroup)
        ) {
          const raw = await this.loadDatagroupData(step.idDataGroup)
          foundDataGroup.dataList = raw.list
          filteredDataGroups.push(foundDataGroup)
        }
      }
      return filteredDataGroups.sort()
    },

    getSteps () {
      if (!this.object || !this.object.activities) {
        return
      }
      let activities = this.object.activities
      if (activities && activities.length > 0) {
        let steps = []
        activities.forEach(activity => {
          steps.push(...activity.list)
        })
        return steps
      }
      return []
    },

    async loadDatagroupData (idDataGroup) {
      const url = `${baseApiUrl}/datagroup/${idDataGroup}`
      const raw = await axios.get(url).catch(showError)
      return raw.data
    },

    adjustColor (color) {
      return tinycolor.mix(color, '#FFFFFF', 60).toString()
    },

    getColor (legal) {
      let color
      switch (legal) {
        case '1':
          color = '#115e59'
          break
        case '2':
          color = '#a3e635'
          break
        case '3':
          color = '#f97316'
          break
        case '4':
          color = '#0a0a0a'
          break
        case '5':
          color = '#fbbf24'
          break
        case '6':
          color = '#dc2626'
          break
        case '7':
          color = '#f87171'
          break
        case '8':
          color = '#d946ef'
          break
        case '9':
          color = '#7dd3fc'
          break
        case '10':
          color = '#15803d'
          break
        case '11':
          color = '#451a03'
          break
        case '12':
          color = '#991b1b'
          break
        case '13':
          color = '#6B47FF'
          break
        case '14':
          color = '#a3a3a3'
          break
        default:
          return ''
      }
      return this.adjustColor(color)
    },

    async loadData () {
      const url = `${baseApiUrl}/data`
      return await axios
        .get(url)
        .then(res => {
          this.data = res.data
          return true
        })
        .catch(showError)
    },

    async loadSectors () {
      const url = `${baseApiUrl}/sector`
      return await axios
        .get(url)
        .then(res => {
          this.sectors = res.data
          return true
        })
        .catch(showError)
    },

    async loadActivities () {
      const url = `${baseApiUrl}/activity`

      return await axios
        .get(url)
        .then(res => {
          this.activities = res.data
          return true
        })
        .catch(showError)
    },

    async loadDataGroups () {
      const url = `${baseApiUrl}/datagroup`
      return await axios
        .get(url)
        .then(res => {
          this.dataGroups = res.data
          return true
        })
        .catch(showError)
    }
  },
  created () {
    this.steps.forEach(step => {
      this.$set(step, 'expanded', false)
    })
  },
  async mounted () {
    this.loadActivities(),
      this.loadSectors(),
      this.loadDataGroups(),
      this.loadData(),
      this.$store.dispatch('setLoadingInfo', true)
    this.$store.dispatch('setLoadingInfo', false)
  }
}
</script>

<style>
.font-weight-bold.ml-1.mt-5 {
  font-size: 20px;
}
.font-weight-bold.mb-0.ml-2.mt-1 {
  font-size: 20px;
}
</style>
